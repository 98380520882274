'use client'

interface ListClickProps {
  listTitle?: string;
  listType: string;
  componentName: string;
  componentID: string;
  componentPosition: number;
  componentCount: number;
  imageSource?: string;
  targetUrl: string;
  targetPageName: string;
  targetPageId?: string;
  listElementTitle: string;
  listElementPosition: number;
  listElementCount: number;
  linkTitle?: string;
  linkType: string
}
const ListClickTracking = ({ listTitle, listType, componentName, componentID, componentCount, componentPosition, imageSource, targetUrl, targetPageId, targetPageName, listElementTitle,  listElementCount, listElementPosition, linkTitle, linkType }: ListClickProps) => {

  // Initialize the digitalData object if it doesn't already exist
  window.digitalData = window.digitalData || {};
  window.digitalData.events = window.digitalData.events || [];

  // Push the new event
  window.digitalData.events.push({
    event: {
      schema: "iglu:be.vrt/list_click/jsonschema/1-1-0",
      data: {
        timestamp: Date.now(),
      },
    },
    context: [
      {
        schema: "iglu:be.vrt/context_list/jsonschema/1-1-3",
        data: {
          listtitle: listTitle,
          listtype: listType,
          componentname: componentName,
          componentid: componentID,
          componentposition: componentPosition,
          componentcount: componentCount,
          containerid: componentID,
        },
      },
      {
        schema: "iglu:be.vrt/context_target/jsonschema/1-1-0",
        data: {
          targetpageurl: targetUrl,
          targetpagename: targetPageName,
          targetpageid: targetPageId,
        },
      },
      {
        schema: "iglu:be.vrt/context_listelement/jsonschema/1-1-1",
        data: {
          listelementtitle: listElementTitle,
          listelementposition: listElementPosition,
          listelementcount: listElementCount,
          imagesource: imageSource,
        },
      },
      {
        schema: "iglu:be.vrt/context_link/jsonschema/1-1-0",
        data: {
          linktitle: linkTitle,
          linktype: linkType,
          componentname: componentName,
          componentid: componentID,
        },
      },
    ],
  });
}

export default ListClickTracking;


