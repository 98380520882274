import Icon from "@/components/atoms/Icon";

export const PlayBtn = () => {
  return (
    <button
      className="mb-4 ml-auto mr-4 mt-auto inline-flex h-12 w-12 items-center justify-center rounded-full bg-grey/60 transition-all hover:scale-110 hover:bg-grey hover:shadow-xl md:mb-auto md:mr-auto lg:h-20 lg:w-20"
      title="Speel video"
      aria-label="Speel video"
    >
      <Icon
        name="play-thin"
        className="relative h-6 w-6 text-white translate-x-[.12rem] lg:translate-x-[.1rem] lg:h-6 lg:w-6"
      />
    </button>
  );
};

export default PlayBtn;
