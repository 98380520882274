"use client";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from "@/components/atoms/Icon";
import React, { useState, useEffect } from 'react';

interface ShareButtonsProps {
  title: string;
}

const ShareButtons = ({ title }: ShareButtonsProps) => {
  const [url, setUrl] = useState<string>('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUrl(window.location.href);
    }
  }, []);

  const shareButtonProps = {
    title,
    url,
    className: "transition text-grey-700 relative border border-transparent transition-colors hover:text-blue group-icon rounded-full grid place-items-center ratio-square",
    resetButtonStyle: false,
  };

  const shareIconProps = {
    className: "h-8 xl:h-8 w-8 x:w-8 ratio-square",
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <div className={"flex flex-col my-4 gap-2 text-center"}>
      {title && (
        <p className={"text-sm"}>{title}</p>
      )}
      <div className="mx-auto">
        <div className="flex flex-wrap gap-2 lg:gap-4 xl:gap-6 md:min-w-fit justify-center font-medium">
          {url && (
            <>
              <FacebookShareButton {...shareButtonProps} >
                <Icon name="share-facebook" {...shareIconProps} />
                <span className={"text-xs xl:text-[.75rem]"}>Facebook</span>
              </FacebookShareButton>

              <TwitterShareButton {...shareButtonProps}>
                <Icon name="share-x" {...shareIconProps} />
                <span className={"text-xs xl:text-[.75rem]"}>X</span>
              </TwitterShareButton>

              <WhatsappShareButton {...shareButtonProps}>
                <Icon name="share-whatsapp" {...shareIconProps} />
                <span className={"text-xs xl:text-[.75rem]"}>Whatsapp</span>
              </WhatsappShareButton>

              <LinkedinShareButton {...shareButtonProps} >
                <Icon name="share-linkedin" {...shareIconProps} />
                <span className={"text-xs xl:text-[.75rem]"}>LinkedIn</span>
              </LinkedinShareButton>

              <EmailShareButton {...shareButtonProps} >
                <Icon name="share-mail" {...shareIconProps} />
                <span className={"text-xs xl:text-[.75rem]"}>Email</span>
              </EmailShareButton>

              <CopyToClipboard text={url} onCopy={handleCopy}>
                <button className={shareButtonProps.className}>
                  <Icon name="share-link" {...shareIconProps} />
                  {copied && <span
                      className="text-xs bg-green text-white px-1 rounded-lg font-semibold animate-[fadeInUp_both_.3s] absolute">Gekopieerd</span>}
                  <span className={"text-xs xl:text-[.75rem]"}>Link</span>
                </button>
              </CopyToClipboard>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareButtons;