import { useEffect, useState } from "react";

/**
 * DELTA: lower number makes the scroll to be more sensitive to direction change
 */
const DELTA = 15;

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const currentScrollY = window.pageYOffset;
      const direction = currentScrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (currentScrollY - lastScrollY > DELTA ||
          currentScrollY - lastScrollY < -DELTA)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = currentScrollY > 0 ? currentScrollY : 0;
      setScrollY(lastScrollY);
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection, scrollY]);

  return {
    scrollDirection,
    scrollY,
  };
}
