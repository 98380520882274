"use client";

import React from 'react';
import {FragmentPartnerBlockFragment} from "@/graphql/sdk/__generated__";
import {PartnerItemParagaph} from "@/components/paragraphs/child-paragraphs/PartnerItemParagaph.tsx";
import {cn} from "@/helpers/className";

interface PartnerBlockParagraphProps {
  className?: string;
  data: FragmentPartnerBlockFragment;
}

const PartnerBlockParagraph = ({data, className}: PartnerBlockParagraphProps) => {
  const partnersCount = Math.min(data.partnersRef.length, 5);
  return (
    <div className={cn(className, "paragraph-partner-block")}>
      {data?.title && (
        <h2 className={"md:text-center max-md:h6 md:h5 !mb-4 md:!mb-6 text-midnight-100"}>{data.title}</h2>
      )}
      {data?.partnersRef && (
        <div
          className={cn(
            `partner-items io-grid grid-cols-2 gap-8 sm:grid-cols-2 lg:grid-cols-${partnersCount} items-center`,
          )}>
          {data.partnersRef.map((item, index) => (
            <PartnerItemParagaph
              key={index}
              item={[item]}/>
          ))}
        </div>
      )}
    </div>
  );
};

export {PartnerBlockParagraph};