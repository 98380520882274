'use client';

import React from 'react';
import ListImpressionTracking from '@/components/tracking/ListImpressionTracking.tsx';
import VerticalVisibilityObserver from "@/components/tracking/VerticalVisibilityObserver.tsx";

interface VerticalListVisibilityTrackerProps {
  listTitle?: string;
  listType?: string;
  componentName: string;
  componentID: string;
  componentPosition: number;
  componentCount: number;
  listElementTitle: string;
  listElementPosition: number;
  listElementCount: number;
  imageSource?: string;
  targetId: string;
}

const VerticalListVisibilityTracker: React.FC<VerticalListVisibilityTrackerProps> = ({
                                                                       listTitle,
                                                                       listType,
                                                                       componentName,
                                                                       componentID,
                                                                       componentPosition,
                                                                       componentCount,
                                                                       listElementTitle,
                                                                       listElementPosition,
                                                                       listElementCount,
                                                                       imageSource,
                                                                       targetId,
                                                                     }) => {
  const handleVisible = () => {
    ListImpressionTracking({
      listTitle,
      listType,
      componentName,
      componentID,
      componentPosition,
      componentCount,
      imageSource,
      listElementTitle,
      listElementPosition,
      listElementCount,
    });
  };

  return <VerticalVisibilityObserver onVisible={handleVisible} targetId={targetId} />;
};

export default VerticalListVisibilityTracker;