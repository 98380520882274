'use client';

import { cn } from "@/helpers/className";
import Image from "next/image";
import ListClickTracking from "@/components/tracking/ListClickTracking.tsx";
import React from "react";
import HorizontalListVisibilityTracker from "@/components/tracking/HorizontalListVisibilityTracker.tsx";

interface SwimLaneTeaserProps {
  data: {
    title: string,
    description: string,
    image: {
      templateUrl: string,
      alt: string,
      focalPoint: any
    },
    action: {
      link: string
    }
  },
  position: number;
  listPosition: number;
  count: number;
  listCount: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
}

export const SwimLaneTeaser = ({
                                 data, listCount, count, listPosition, position, parentTitle, parentType, parentID
                               }: SwimLaneTeaserProps) => {
  const imageUrl = data.image.templateUrl.replace('orig', 'w320hx');
  const handleListClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // Prevent the default link behavior

    ListClickTracking({
      listTitle: parentTitle,
      componentName: parentType,
      componentID: parentID,
      componentCount: count,
      componentPosition: position,
      imageSource: imageUrl,
      targetUrl: "https://vrt.be" + data.action.link,
      targetPageId: data.action.link,
      targetPageName: data.action.link,
      listElementTitle: data.title,
      listElementPosition: listPosition,
      listElementCount: listCount,
      linkTitle: data.title,
      linkType: "image",
      listType: "horizontal",
    });

    // Navigate to the URL after tracking
    window.open(data.action.link, '_blank', 'noopener,noreferrer');
  };


  return (
    <div
      id={`list-item-${parentID}-${listPosition}`}
      className={cn("swimlane-teaser flex flex-col items-center")}
    >
      <HorizontalListVisibilityTracker
        listTitle={parentTitle}
        listType={parentType}
        componentID={parentID}
        componentName={parentType}
        componentPosition={position}
        componentCount={count}
        listElementTitle={data.title}
        listElementPosition={listPosition}
        listElementCount={listCount}
        imageSource={imageUrl}
        targetId={`list-item-${parentID}-${listPosition}`}
      />
      <a
        className={"relative aspect-[5/7] overflow-hidden flex shrink-0 rounded-lg group w-[200px] md:w-[224px] xl:w-[260px]"}
        href={data.action.link}
        target={"_blank"}
        rel={"nofollow noreferrer"}
        onClick={handleListClick}
      >
        <Image
          src={imageUrl}
          alt={`${data.image.alt || data.title || ''} op VRT MAX`}
          className="w-full h-full transition-opacity group-hover:opacity-80 object-cover"
          width={320}
          height={640}
          quality={90}
        />
      </a>
    </div>
  );
};
