import React, { useEffect } from 'react';

interface VisibilityObserverProps {
  onVisible: () => void;
  targetId: string;
}

const trackedElements = new Set<string>();

const HorizontalVisibilityObserver: React.FC<VisibilityObserverProps> = ({ onVisible, targetId }) => {
  useEffect(() => {
    const targetElement = document.getElementById(targetId);

    if (!targetElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.75) {
            if (!trackedElements.has(targetId)) {
              onVisible();
              trackedElements.add(targetId);
            }
          }
        });
      },
      {
        root: null, // Use the viewport as the container
        rootMargin: '0px',
        threshold: [0.75], // Trigger when 75% of the target is visible
      }
    );

    observer.observe(targetElement);

    return () => {
      observer.unobserve(targetElement);
    };
  }, [onVisible, targetId]);

  return null;
};

export default HorizontalVisibilityObserver;