'use client';

import { useEffect, useRef, useState } from 'react';

interface VisibilityObserverProps {
  onVisible: () => void;
  targetId: string;
}

const VerticalVisibilityObserver: React.FC<VisibilityObserverProps> = ({ onVisible, targetId }) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [hasTriggered, setHasTriggered] = useState(false);

  useEffect(() => {
    const target = document.getElementById(targetId);

    if (!target) {
      console.warn('Target element not found');
      return;
    }

    const recalculateVisibility = () => {
      const rect = target.getBoundingClientRect();
      const targetHeight = rect.height;
      const targetTop = rect.top;
      const viewportHeight = window.innerHeight;
      const triggerPoint = targetHeight * 0.75;

      if (!hasTriggered && (viewportHeight - targetTop) >= triggerPoint) {
        onVisible();
        setHasTriggered(true);
      }
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          recalculateVisibility();
        }
      });
    };

    observerRef.current = new IntersectionObserver(observerCallback, {
      threshold: 0, // We only need to know when the element enters the viewport
    });

    observerRef.current.observe(target);

    const handleScrollOrResize = () => {
      recalculateVisibility();
    };

    window.addEventListener('scroll', handleScrollOrResize);
    window.addEventListener('resize', handleScrollOrResize);

    // Cleanup observer and event listeners on component unmount
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      window.removeEventListener('scroll', handleScrollOrResize);
      window.removeEventListener('resize', handleScrollOrResize);
    };
  }, [onVisible, targetId, hasTriggered]);

  return null;
};

export default VerticalVisibilityObserver;