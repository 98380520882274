"use client";

import { useState, useEffect, useRef } from 'react';
import { cn } from "@/helpers/className";
import { Select } from 'antd';
import Icon from "@/components/atoms/Icon";

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  const debouncedFunction = (...args) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedFunction;
};

export const TocList = ({ enabled, content }) => {
  const [headings, setHeadings] = useState([]);
  const [activeId, setActiveId] = useState("");
  const [lineHeight, setLineHeight] = useState(0);
  const tocRef = useRef(null);

  useEffect(() => {
    if (enabled) {
      const headingElements = document.querySelectorAll('.paragraph .prose h2');
      const headingList = Array.from(headingElements).map((heading, index) => {
        // Generate a unique ID for each heading
        const id = heading.innerText.toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '') + '-' + index;
        heading.id = id;

        return {
          id,
          text: heading.innerText,
        };
      });
      setHeadings(headingList);

      // Intersection Observer to track which heading is in view
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      }, { rootMargin: '0px 0px -80% 0px' });

      headingElements.forEach((heading) => observer.observe(heading));

      // Additional Intersection Observer for the top of the page
      const topObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.boundingClientRect.top < 0) {
            setActiveId("");
          }
        });
      }, { threshold: [0] });

      topObserver.observe(document.querySelector('body'));

      return () => {
        headingElements.forEach((heading) => observer.unobserve(heading));
        topObserver.disconnect();
      };
    }
  }, [enabled, content]);

  const updateLineHeight = () => {
    if (tocRef.current) {
      const activeElement = tocRef.current.querySelector(`a[href="#${activeId}"]`)?.parentElement;
      if (activeElement) {
        const tocRect = tocRef.current.getBoundingClientRect();
        const activeRect = activeElement.getBoundingClientRect();
        setLineHeight(activeRect.top - tocRect.top + (activeRect.height / 2));
      } else {
        setLineHeight(0);
      }
    }
  };

  // Debounced version of updateLineHeight
  const debouncedUpdateLineHeight = useDebounce(updateLineHeight, 300);

  useEffect(() => {
    debouncedUpdateLineHeight();
  }, [activeId]);

  const handleSelectChange = (value) => {
    const targetElement = document.getElementById(value);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
      setActiveId(value);
      debouncedUpdateLineHeight();
    }
  };

  const handleAnchorClick = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
      setActiveId(id);
      debouncedUpdateLineHeight();
    }
  };

  if (!enabled) {
    return null;
  }

  return (
    <div className={"h-full"}>
      {/* Mobile Toc Dropdown */}
      <div className="sticky top-[var(--header-height)] xl:hidden mb-4">
        <Select
          showSearch
          placeholder="Inhoudstafel"
          suffixIcon={<Icon name="arrow-vrt" className="w-4 h-4 text-grey cursor-pointer rotate-90" />}
          style={{ width: '100%' }}
          onChange={handleSelectChange}
        >
          {headings.map(heading => (
            <Select.Option key={heading.id} value={heading.id}>
              {heading.text}
            </Select.Option>
          ))}
        </Select>
      </div>
      {/* Desktop ToC List */}
      <div className={"sticky top-[var(--header-height)] py-6 pr-10 max-h-[70vh] overflow-y-auto no-scrollbar overflow-gradient-tb max-xl:hidden pt-12 -mt-12"} ref={tocRef}>
        <div className="relative overflow-y-hidden py-1">
          {/* Grey Background Line */}
          <div className="absolute left-0 top-0 w-[3px] bg-grey/10 h-full rounded-full"></div>
          {/* Progress Line */}
          <div className="absolute left-0 -top-[.8rem] w-[3px] bg-blue transition-all duration-300 rounded-full" style={{ height: `${lineHeight}px` }}></div>
          <ul className={"toc-nav -mt-1"}>
            {headings.map(heading => (
              <li
                key={heading.id}
                className={cn(
                  heading.id === activeId ? "active !text-blue border-b-blue/60" : "hover:text-blue",
                  'toc-nav__item pt-[.25rem] pb-[.35rem] border-b border-dashed transition-colors font-medium !leading-[.85rem]',
                )}>
                <a href={`#${heading.id}`} className={"text-[.7rem]"} onClick={() => handleAnchorClick(heading.id)}>{heading.text}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};