'use client';

import React from 'react';
import Link from 'next/link'; // Assuming you're using next/link for navigation
import {cn} from "@/helpers/className";
import ListClickTracking from "@/components/tracking/ListClickTracking.tsx"; // Adjust the import path accordingly

interface ListLinkProps {
  data: {
    link: string;
    title: string;
    imageUrl?: string;
    __typename?: string;
    id?: string;
  };
  count: number;
  position: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
  platform?: string;
  listElementTitle: string;
  listElementPosition: number;
  listElementCount: number;
  linkType: string;
  listType: string;
  targetPageName: string;
}

const ListLink = ({ data, count, position, parentTitle, parentType, parentID, platform, listElementCount, listElementPosition, listElementTitle, targetPageName, linkType, listType }: ListLinkProps) => {
  const handleListClick = () => {
    try {
      ListClickTracking({
        listTitle: parentTitle,
        componentName: parentType,
        componentID: parentID,
        componentCount: count,
        componentPosition: position,
        imageSource: data.imageUrl ?? "",
        targetUrl: data.link,
        targetPageName: targetPageName,
        targetPageId: data.link,
        linkTitle: data.title,
        listElementPosition: listElementPosition,
        listElementCount: listElementCount,
        listElementTitle: listElementTitle,
        linkType: linkType,
        listType: listType,
      });
    } catch (error) {
      console.error('Error in ListClickTracking:', error);
    }
  };

  return (
    <Link
      href={data.link}
      target="_blank"
      className={cn(
        "stretched-link mr-auto !leading-snug",
      )}
      rel={"noopener noreferrer"}
      onClick={handleListClick}>
      {/*{`${data.title || ''} ${platform || ''}`}*/}
      {`${data.title || ''}`}
    </Link>
  );
};

export default ListLink;
