'use client';

interface BannerClickProps {
  bannerTitle?: string;
  bannerSubtitle?: string;
  componentName: string;
  componentID: string;
  componentPosition: number;
  componentCount: number;
  imageSource?: string;
  targetUrl: string;
  targetPageName?: string;
  targetPageId?: string;
  linkTitle?: string;
  linkType: string
}

const BannerClickTracking = ({ bannerTitle, bannerSubtitle, componentName, componentID, componentCount, componentPosition, imageSource, targetUrl, targetPageId, targetPageName, linkTitle, linkType }: BannerClickProps) => {
  // Initialize the digitalData object if it doesn't already exist
  window.digitalData = window.digitalData || {};
  window.digitalData.events = window.digitalData.events || [];


  // Push the new event
  window.digitalData.events.push({
    event: {
      schema: "iglu:be.vrt/banner_click/jsonschema/1-1-0",
      data: {
        timestamp: Date.now(),
      },
    },
    context: [
      {
        schema: "iglu:be.vrt/context_banner/jsonschema/1-1-4",
        data: {
          bannertitle: bannerTitle ?? "",
          bannersubtitle: bannerSubtitle ?? "",
          componentname: componentName,
          componentid: componentID,
          componentposition: componentPosition,
          componentcount: componentCount,
          containerid: componentID,
          imagesource: imageSource,
        },
      },
      {
        schema: "iglu:be.vrt/context_target/jsonschema/1-1-0",
        data: {
          targetpageurl: targetUrl,
          targetpagename: targetPageName,
          targetpageid: targetPageId,
        },
      },
      {
        schema: "iglu:be.vrt/context_link/jsonschema/1-1-0",
        data: {
          linktitle: linkTitle,
          linktype: linkType,
          componentname: componentName,
          componentid: componentID,
        },
      },
    ],
  });
};

export default BannerClickTracking;