"use client";

import type { NewsOverviewResult } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { TeaserNews } from "@/components/molecules/TeaserNews";
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { Pagination, Skeleton } from 'antd';
import { useTransition, useCallback } from 'react';

interface NewsOverviewListWithoutFiltersProps {
  className?: string;
  results: NewsOverviewResult[];
  pageInfo: {
    total: number;
    page: number;
    pageSize: number;
  };
  position: number;
  count: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
}

export const NewsOverviewListWithoutFilters = ({
                                                 results = [],
                                                 className,
                                                 pageInfo,
                                                 position,
                                                 count,
                                                 parentTitle,
                                                 parentType,
                                                 parentID
                                               }: NewsOverviewListWithoutFiltersProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [isPending, startTransition] = useTransition();

  const handlePageChange = useCallback((page: number) => {
    startTransition(() => {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.set('pagina', (page - 1).toString());
      router.push(`${pathname}?${newParams.toString()}`);
    });
  }, [router, searchParams, pathname]);

  return (
    <>
      <div className="mb-12 lg:mb-24">
        <div>
          {isPending ? (
            <div className={cn('blog-list-items grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-6 grid lg:gap-y-10 lg:gap-x-6', className)}>
              {Array.from({ length: 8 }).map((_, index) => (
                <div key={index} className="flex flex-col gap-4 bg-grey-100/80 p-6 rounded-xl">
                  <Skeleton.Avatar active size="large" shape="square" />
                  <Skeleton active />
                </div>
              ))}
            </div>
          ) : (
            <div>
              {results.length > 0 ? (
                <div>
                  <div className={cn('blog-list-items grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-6 grid lg:gap-y-10 lg:gap-x-6', className)}>
                    {results.map((item, index) => (
                      <div
                        key={item.id}
                        className="flex flex-col gap-4 animate-[fadeIn_both_.3s]"
                        style={{ animationDelay: `${index * 0.015}s` }}
                      >
                        <TeaserNews
                          title={item.title}
                          thema={item.thema}
                          media={item.teaserMedia?.imageMediaImage ?? undefined}
                          link={{
                            url: item.path,
                            internal: true,
                          }}
                          position={position}
                          listPosition={index+1}
                          count={count}
                          listCount={results.length}
                          parentTitle={parentTitle}
                          parentType={parentType}
                          parentID={parentID}
                          listType={"grid"}
                          linkType={"image"}
                        />
                      </div>
                    ))}
                  </div>
                  {pageInfo && pageInfo.total / pageInfo.pageSize > 1 && (
                    <Pagination
                      total={pageInfo.total}
                      current={pageInfo.page + 1}
                      pageSize={pageInfo.pageSize}
                      showSizeChanger={false}
                      onChange={handlePageChange}
                      className="number-pagination !mt-6 lg:mt-12 xl:!mt-14 w-full inline-flex justify-center mx-auto"
                    />
                  )}
                </div>
              ) : (
                <div className="text-red-500 mt-6">
                  Er zijn geen resultaten gevonden. Probeer een andere combinatie van filters.
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
