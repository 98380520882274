import { Fragment, useEffect } from "react";
import { usePathname } from "next/navigation";
import { Dialog, Transition } from "@headlessui/react";
import Link from "next/link";
import Icon from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";

interface Link {
  title: string;
  url: string;
}

interface MobileMenuSubSiteProps {
  mobileMenuSubsiteOpen: boolean;
  subSiteLinks: Link[];
  setMobileMenuSubsiteOpen: (open: boolean) => void;
}

export const MobileMenuSubsite = ({
                                    mobileMenuSubsiteOpen,
                                    subSiteLinks,
                                    setMobileMenuSubsiteOpen,
                                  }: MobileMenuSubSiteProps) => {
  const pathname = usePathname();

  useEffect(() => {
    setMobileMenuSubsiteOpen(false);
  }, [pathname, setMobileMenuSubsiteOpen]);

  return (
    <Transition.Root show={mobileMenuSubsiteOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 xl:hidden"
        onClose={setMobileMenuSubsiteOpen}
      >
        <div className="fixed inset-0 z-50 bg-grey/80 backdrop-blur animate-fadeIn" />
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-150 sm:duration-300 delay-[.15s]"
          enterFrom="opacity-0 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transform transition ease-in-out duration-150 delay-0"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-0"
          show={mobileMenuSubsiteOpen}
        >
          <button
            type="button"
            className={cn(
              `btn-mobile-nav-close group fixed top-2 p-0 right-4 z-[51] bg-white border-2 border-midnight-700/40 text-midnight-700 w-9 h-9 rounded-full inline-flex items-center justify-center`
            )}
            onClick={() => setMobileMenuSubsiteOpen(false)}
            aria-label={"Sluit mobiele navigatie"}
          >
            <Icon name="close" className="h-4 w-4" />
          </button>
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-150 sm:duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-150 sm:duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          show={mobileMenuSubsiteOpen}
        >
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between gap-4 h-[var(--header-height)]"></div>
            <div className="flex px-6 py-6 flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                {subSiteLinks && (
                  <div>
                    <nav aria-label={"subsite navigation"} className={"subsite-nav"}>
                      <ul className={"flex flex-col gap-4 lg:gap-6 list-style-none"}>
                        {subSiteLinks.map((link, index) => (
                          <li key={index}>
                            <Link
                              href={link.url}
                              className={cn(
                                "inline-flex items-center gap-2 text-base transition-colors text-grey max-lg:font-semibold max-lg:w-full max-lg:border-b max-lg:pb-4",
                              )}
                            >
                              {link.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};