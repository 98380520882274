'use client';

import { cn } from "@/helpers/className";
import React, { useState } from "react";
import { AccordionItem } from "@/components/molecules/AccordeonItem";

interface AccordeonParagraphProps {
  className?: string;
  data: any;
  position: number;
  count: number;
}

export const AccordeonParagraph = ({className, data, count, position}: AccordeonParagraphProps) => {
  const accordeonItems = data.accordeonItems.filter(item =>
    !(item.__typename === 'ParagraphFaqItem' && item.faqRef === null)
  );
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const toggleAccordion = (index: number) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
      <div className={cn(className, "paragraph--accordions overflow-x-hidden")}>
        {data.paragraphAccordeonTitle && (
          <div className={`flex flex-col max-w-screen-lg mx-auto`}>
            <h2 className={"flex mb-4 lg:!mb-6 xl:!mb-8"}>{data.paragraphAccordeonTitle}</h2>
          </div>
        )}
        {accordeonItems && Array.isArray(accordeonItems) && accordeonItems.length > 0 && (
          <div itemScope itemType="https://schema.org/FAQPage">
            <div className={`flex flex-col max-w-screen-lg mx-auto io-builder--sort-wrapper accordion-list`}>
              {accordeonItems.map((accordeanItem, index) => (
                <AccordionItem
                  key={index}
                  data={accordeanItem}
                  isActive={index === activeIndex}
                  onToggle={() => toggleAccordion(index)}
                  position={position}
                  listPosition={index}
                  count={count}
                  listCount={accordeonItems.length}
                  parentTitle={data.paragraphAccordeonTitle}
                  parentType={data.__typename}
                  parentID={data.id}
                />
              ))}
            </div>
          </div>
        )}
      </div>
  );
};
