'use client';

import React from 'react';
import VerticalVisibilityObserver from '@/components/tracking/VerticalVisibilityObserver.tsx';
import BannerImpressionTracking from '@/components/tracking/BannerImpressionTracking.tsx';

interface BannerVisibilityTrackerProps {
  componentID: string;
  bannerTitle: string;
  bannerSubtitle?: string;
  componentName: string;
  imageSource: string;
  componentPosition: number;
  componentCount: number;
  targetId: string;
}

const BannerVisibilityTracker: React.FC<BannerVisibilityTrackerProps> = ({
                                                                           componentID,
                                                                           bannerTitle,
                                                                           bannerSubtitle,
                                                                           componentName,
                                                                           imageSource,
                                                                           componentPosition,
                                                                           componentCount,
                                                                           targetId
                                                                         }) => {
  const handleVisible = () => {
    // Trigger the tracking event when the component is 75% visible
    BannerImpressionTracking({
      componentID,
      bannerTitle,
      bannerSubtitle,
      componentName,
      imageSource,
      componentPosition,
      componentCount
    });
  };

  return (
    <VerticalVisibilityObserver onVisible={handleVisible} targetId={targetId} />
  );
};

export default BannerVisibilityTracker;