import React, { useEffect, useState } from 'react';
import {cn} from "@/helpers/className";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import Image from "next/image";
import ListLink from "@/components/atoms/ListLink.tsx";
import HorizontalListVisibilityTracker from "@/components/tracking/HorizontalListVisibilityTracker.tsx";

interface VrtNwsProps {
  data: {
    imageUrl: string,
    link: string,
    updatedDate: string,
    title: string,
  },
  position: number;
  listPosition: number;
  count: number;
  listCount: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
}

export const VrtNwsTeaser = ({data, listCount, count, listPosition, position, parentTitle, parentType, parentID}: VrtNwsProps) => {
  const [timeElapsed, setTimeElapsed] = useState('');
  const platform = 'op VRT NWS';

  useEffect(() => {
    const updatedDate = parseISO(data.updatedDate);
    const formattedTimeElapsed = formatDistanceToNow(updatedDate, {
      addSuffix: true,
      locale: nl, // Set the locale to Dutch
    });
    setTimeElapsed(formattedTimeElapsed);
  }, [data.updatedDate]);
  return (
    <div
      id={`list-item-${parentID}-${listPosition}`}
      className={cn(
        "default-teaser relative flex flex-col items-stretch group",
      )}
    >
      <HorizontalListVisibilityTracker
        listTitle={parentTitle}
        listType={parentType}
        componentID={parentID}
        componentName={parentType}
        componentPosition={position}
        componentCount={count}
        listElementTitle={data.title}
        listElementPosition={listPosition}
        listElementCount={listCount}
        imageSource={data?.imageUrl ?? ""}
        targetId={`list-item-${parentID}-${listPosition}`}
      />
      <div className="relative w-full h-[160px] md:h-[200px] xl:h-[220px] 2xl:h-[240px]  overflow-hidden flex shrink-0 items-center justify-center rounded-lg">
        <Image
          src={data.imageUrl}
          alt={data.title || 'VRT NWS image'}
          className="w-full transition-transform duration-300 xl:group-hover:scale-105 h-full object-cover"
          width={600}
          height={400}
          sizes={"25vw"}
          quality={90}
        />
      </div>

      <div className="flex flex-1 mt-2 lg:mt-3">
        <div className="w-full h-full flex flex-col">
          {data.link && data.title && (
            <ListLink
              data={data}
              count={count}
              position={position}
              parentTitle={parentTitle}
              parentType={parentType}
              parentID={parentID}
              targetPageName={data.link.replace(/https:\/\/(www\.)?vrt\.be/, '')}
              listElementCount={listCount}
              listElementTitle={data.title}
              listElementPosition={listPosition}
              platform={platform}
              linkType={"image"}
              listType={"horizontal"}
            />
          )}
          {timeElapsed && <p className="mt-2 text-sm">{timeElapsed}</p>}
        </div>
      </div>
    </div>
  );
};
