'use client';

interface BannerImpressionProps {
  bannerTitle?: string;
  bannerSubtitle?: string;
  componentName: string;
  componentID: string;
  componentPosition: number;
  componentCount: number;
  imageSource?: string;
}

const BannerImpressionTracking = ({ bannerTitle, bannerSubtitle, componentName, componentID, componentCount, componentPosition, imageSource }: BannerImpressionProps) => {
  // Initialize the digitalData object if it doesn't already exist
  window.digitalData = window.digitalData || {};
  window.digitalData.events = window.digitalData.events || [];

  // Push the new event
  window.digitalData.events.push({
    event: {
      schema: "iglu:be.vrt/banner_impression/jsonschema/1-1-0",
      data: {
        timestamp: Date.now(),
      },
    },
    context: [
      {
        schema: "iglu:be.vrt/context_banner/jsonschema/1-1-4",
        data: {
          bannertitle: bannerTitle,
          bannersubtitle: bannerSubtitle ?? "",
          componentname: componentName,
          componentid: componentID,
          componentposition: componentPosition,
          componentcount: componentCount,
          containerid: componentID,
          imagesource: imageSource,
        },
      },
    ],
  });
}



export default BannerImpressionTracking;
