"use client";

import {useRef} from "react";
import dynamic from "next/dynamic";

import {
  FragmentParagraphVideoFragment,
  ImageStyleAvailable,
  ParagraphVideo,
} from "@/graphql/sdk/__generated__";
import {cn} from "@/helpers/className";
import {getVariation} from "@/helpers/image";
import PlayBtn from "@/components/atoms/PlayBtn";

interface VideoParagraphProps {
  className?: string;
  data: FragmentParagraphVideoFragment;
  narrow?: boolean;
}

const VideoPlayerNoSSR = dynamic(
  () => import("@/components/atoms/VideoPlayer"),
  {ssr: false},
);

export const VideoParagraph = ({data, className, narrow}: VideoParagraphProps) => {
  const videoUrl = data.paragraphVideoMedia.mediaOembedVideo as string;
  const boxDisplay = data.entity_type_behaviors?.box_display !== "0";
  const containerClass = boxDisplay || narrow ? "" : "break-free";

  let videoId;
  // Get Youtube video id from video url or embed url
  if (videoUrl.includes("embed/")) {
    videoId = videoUrl.split("embed/")[1];
  } else if (videoUrl.includes("?v=")) {
    videoId = videoUrl.split("?v=")[1];
  } else if (videoUrl.includes("?si=")) {
    videoId = videoUrl.replace(/(^\w+:|^)\/\//, '').split("?si=")[0].split("/")[1];
  } else {
    videoId = videoUrl.replace(/(^\w+:|^)\/\//, '').split("/")[1];
  }

  const posterUrl = data.paragraphVideoMedia.remoteVideoMediaImage?.variations
    ? getVariation(
      data.paragraphVideoMedia.remoteVideoMediaImage?.variations,
      ImageStyleAvailable.LandscapeRatio32,
    ).url
    : `https://i.ytimg.com/vi/${videoId}/0.jpg`;

  const playerRef = useRef();
  const videoPlayer = (
    <>
      <h2 className="sr-only">Video</h2>
      <div className="aspect-video">
        <VideoPlayerNoSSR
          playerRef={playerRef}
          videoUrl={videoUrl}
          boxDisplay={boxDisplay}
          playBtn={<PlayBtn/>}
          posterUrl={posterUrl || undefined}
        />
      </div>
      {data.caption && (
        <div
          className={cn("mx-auto mt-3 text-sm text-grey-700/60", !boxDisplay && "container")}
          dangerouslySetInnerHTML={{
            __html: data.caption.processed as string,
          }}

        ></div>
      )}
    </>
  );
  return (
    <div
      className={cn(
        "paragraph paragraph--video",
        containerClass,
        className
      )}
    >
      <div className={cn(
        boxDisplay ? '' : 'container mx-auto',
      )}>
        {videoPlayer}
      </div>
    </div>
  );
};
