"use client";

import {Fragment, useEffect, useState} from "react";
import Link from "next/link";;
import {usePathname} from "next/navigation";
import {ImageFieldsFragment} from "@/graphql/sdk/__generated__";
import {cn} from "@/helpers/className";
import {useScrollDirection} from "@/lib/hooks/useScrollDirection";
import Icon from "@/components/atoms/Icon.tsx";
import {MobileMenuSubsite} from "@/components/molecules/MobileMenuSubsite.tsx";

interface Link {
  url: string;
  title: string;
}

interface HeaderSubsiteProps {
  svgImage?: ImageFieldsFragment;
  reverseImage?: ImageFieldsFragment;
  subSiteLinks?: Link[];
  mainLink?: Link;
}

export const HeaderSubsite = ({
                                svgImage,
                                reverseImage,
                                subSiteLinks,
                                mainLink,
                              }: HeaderSubsiteProps) => {

  useEffect(() => {
    const subSitePageClass = 'subsite-page';
    document.body.classList.add(subSitePageClass);

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove(subSitePageClass);
    };
  }, []);

  const [mobileMenuSubsiteOpen, setMobileMenuSubsiteOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const pathname = usePathname();
  const {scrollDirection, scrollY} = useScrollDirection();
  const subSiteHeaderHiddenClass = "subsite-header--hidden !top-0 duration-300";
  const subSiteHeaderNormalClass = " top-[var(--header-height)] duration-300";
  const subSiteHeaderCondensedClass = " top-[var(--header-height)] duration-300";
  return (
    <div
      className={cn(
        "subsite-header z-50 trans-timing-default transition-all fixed inset-x-0 @apply bg-white border-b border-grey-100",
        scrollY === 0 ? "subsite-header-top" : "subsite-header-scrolled",
        scrollY > 200 ? subSiteHeaderCondensedClass : subSiteHeaderNormalClass,
        scrollDirection === "down" && scrollY >= 200 ? subSiteHeaderHiddenClass : "",
        searchOpen ? subSiteHeaderHiddenClass : "",
      )}
      role="banner">
      <div
        className={cn(
          "subsite-header__inner shadow-sm h-full w-full",
          reverseImage && 'subsite-header__inner--w-inverse-logo',
        )}>
        <div
          className={cn(
            "flex gap-4 container mx-auto h-full w-full items-center justify-between",
          )}>
          {svgImage && (
              <div className={"subsite-logo subsite-logo--default"}>
                <Link href={mainLink}>
                  <img
                      src={svgImage.url}
                      className={"w-full h-auto object-contain max-w-[150px] max-h-[calc(var(--header-height)-1.4rem)] mr-auto"}
                      alt={svgImage.alt}/>
                </Link>
              </div>
          )}
          {(reverseImage) && (
              <div className={"subsite-logo subsite-logo--inverse hidden"}>
                <Link href={mainLink}>
                  <img
                      src={reverseImage.url}
                      className={"w-full h-auto object-contain max-w-[150px] max-h-[calc(var(--header-height)-1.4rem)] mr-auto"}
                      alt={reverseImage.alt}/>
                </Link>
              </div>
          )}
          {subSiteLinks && (
            <div>
              <nav aria-label={"subsite navigation"} className={"subsite-nav max-lg:hidden"}>
                <ul className={"flex gap-4 lg:gap-6 list-style-none"}>
                  {subSiteLinks && subSiteLinks.map((link, index) => (
                    <li key={index}>
                      <Link
                        href={link.url}
                        className={cn(
                          "inline-flex items-center hover:text-blue font-semibold transition-colors px-0 py-1 rounded-full text-sm",
                          pathname === link.url
                            ? "text-blue"
                            : "text-grey",
                        )}
                      >
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>

              <button
                type="button"
                className="btn inline-flex h-8 w-8 items-center justify-center lg:hidden"
                onClick={() => setMobileMenuSubsiteOpen(true)}
              >
                <span className="sr-only">Open subsite menu</span>
                <span className="flex w-5 h-5 flex-col">
                  <Icon name="hamburger" className="h-5 w-5 group-hover:!fill-white transition-colors"/>
                </span>
              </button>
              <MobileMenuSubsite
                mobileMenuSubsiteOpen={mobileMenuSubsiteOpen}
                subSiteLinks={subSiteLinks}
                setMobileMenuSubsiteOpen={setMobileMenuSubsiteOpen}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
