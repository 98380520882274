'use client';

import React from 'react';
import Link from 'next/link'; // Assuming you're using next/link for navigation
import ListClickTracking from "@/components/tracking/ListClickTracking.tsx"; // Adjust the import path accordingly
import { cn } from '@/helpers/className';

interface ListLinkProps {
  data: {
    link: string;
    title: string;
    imageUrl?: string;
    __typename?: string;
    id?: string;
  };
  count: number;
  position: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
  listElementTitle: string;
  listElementPosition: number;
  listElementCount: number;
  listType: string;
  linkType: string
}

const NewsLink = ({ data, count, position, parentTitle, parentType, parentID, listElementCount, listElementPosition, listElementTitle, listType, linkType }: ListLinkProps) => {
  const handleListClick = () => {
    try {
      ListClickTracking({
        listTitle: parentTitle,
        componentName: parentType,
        componentID: parentID,
        componentCount: count,
        componentPosition: position,
        imageSource: data.imageUrl ?? "",
        targetUrl: "https://vrt.be" + data.link,
        targetPageId: data.link,
        targetPageName: data.link,
        linkTitle: data.title,
        listElementPosition: listElementPosition,
        listElementCount: listElementCount,
        listElementTitle: listElementTitle,
        listType: listType,
        linkType: linkType,
      });
    } catch (error) {
      console.error('Error in ListClickTracking:', error);
    }
  };

  return (
    <Link
      href={data.link}
      className={cn("stretched-link mr-auto !leading-none")}
      onClick={handleListClick}
    >
      <span className="link no-underline pb-1 font-semibold lg:text-lg  line-clamp-3 !leading-[1.4]">
        {data.title}
      </span>
    </Link>
  );
};

export default NewsLink;