"use client";

import React from 'react';
import DrupalImage from "@/components/atoms/DrupalImage";
import {ImageStyleAvailable} from "@/graphql/sdk/__generated__";
import {cn} from "@/helpers/className";
import {getVariation} from "@/helpers/image";

interface PartnerItemParagaphProps {
  item: any;
}

export const PartnerItemParagaph = ({item}: PartnerItemParagaphProps) => {
  const media = item[0].partnerImage.imageMediaImage;
  const imageMedia = item[0].partnerImage.imageMediaImage;
  const svgMedia = item[0].partnerImage.svgMediaImage;

  if (!imageMedia?.variations && !svgMedia) {
    return <></>;
  }
  if (svgMedia) {
    const isLinkPresent = !!item[0].link?.url;
    return (
      <div
        className={cn(
          "paragraph-partner-block flex justify-center w-full h-full ",
          isLinkPresent ? "" : "w-full h-full max-h-[80px] lg:max-h-[120px] max-w-[160px] lg:max-w-[200px]",
        )}>
        {isLinkPresent ? (
          <a href={item[0].link.url} target={item[0]?.link?.attributes.target} className={"flex w-full h-full max-h-[80px] lg:max-h-[120px] max-w-[160px] lg:max-w-[200px]"}>
            <img src={svgMedia.url} alt={svgMedia.title} className={"mx-auto w-auto max-w-full object-contain"} />
          </a>
        ) : (
          <img src={svgMedia.url} alt={svgMedia.title} className={"mx-auto w-auto max-w-full object-contain"} />
        )}
      </div>
    );
  }

  const imageStyleMap = {
    'free': ImageStyleAvailable.Free,
    'landscape': ImageStyleAvailable.LandscapeRatio32,
    'portrait': ImageStyleAvailable.PortraitRatio23,
  };

  let imageStyle = item[0].entity_type_behaviors?.image_style;
  imageStyle = imageStyleMap[imageStyle] || ImageStyleAvailable.BannerRatio21;

  const image_width = item[0].entity_type_behaviors?.image_width;
  const image = getVariation(media.variations, imageStyle);

  if (!image) {
    console.error(`Can't find image with crop ${imageStyle}`);
    return <></>;
  }

  return (
    <div className="partner-item">
      <a href={item[0].link.url} className={"flex max-h-[80px] lg:max-h-[120px] max-w-[160px] lg:max-w-[200px] w-full h-full"}>
        <DrupalImage
          url={image.url}
          alt={media.alt}
          width={image.width}
          height={image.height}
          sizes="100vw"
          className={cn(
            image_width === 'extended' ? 'w-full' : 'mx-auto w-auto max-w-full object-contain ',
          )}
        />
      </a>
    </div>
  );
};
